<template>
    <!--编辑页与详情页相同-->

    <div class="GoodsEdit" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="goods" label-width="80px" :model="goods" :rules="rules" @submit.native.prevent size="small">
                <el-form-item label="名称">
                    {{ form.sku.name }}
                </el-form-item>
                <el-form-item label="部门名称">
                    {{ form.deptName }}
                </el-form-item>
                <el-form-item label="条码">
                    <span v-for="b in form.sku.bars" :key="b">{{ b }}<br /></span>
                </el-form-item>
                <el-form-item label="类型">
                    {{ form.sku.type | goodsType }}
                </el-form-item>
                <el-form-item label="规格">
                    {{ form.sku.specs }}
                </el-form-item>
                <el-form-item label="类目">
                    {{ form.sku.category }}
                </el-form-item>
                <el-form-item label="单位(小)">
                    {{ form.sku.unit }}
                </el-form-item>
                <el-form-item label="品牌">
                    {{ form.sku.brand }}
                </el-form-item>
                <el-form-item label="保质期">
                    {{ form.sku.expirationDate }}
                </el-form-item>
                <el-form-item label="单位(大)" prop="bigUnit" v-if="hasBigUnitPrivilege && form.sku.type == 0">
                    <el-input v-model="goods.bigUnit" />
                </el-form-item>
                <el-form-item label="包装因子" prop="packFactor" v-if="hasBigUnitPrivilege && !form.sku.type">
                    <el-input-number type="number" :controls="false" v-model.number="goods.packFactor" />
                </el-form-item>
                <el-form-item label="自编码" prop="customBar">
                    <el-input v-model="goods.customBar" oninput="value=value.replace(/[^\d]/g,'')" />
                </el-form-item>
                <el-form-item label="助记码" prop="fastBar">
                    <el-input
                        v-model="goods.fastBar"
                        onkeyup="value=value.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g,'')"
                        onpaste="value=value.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g,'')"
                        oncontextmenu="value=value.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g,'')"
                    />
                </el-form-item>
                <el-form-item label="最低库存" prop="minStock">
                    <el-input-number type="number" :controls="false" v-model.number="goods.minStock" />
                </el-form-item>
                <el-form-item label="最高库存" prop="maxStock">
                    <el-input-number type="number" :controls="false" v-model.number="goods.maxStock" />
                </el-form-item>
                <el-form-item label="状态">
                    <el-select placeholder="商品类型" v-model="goods.status">
                        <el-option label="已下架" :value="0" />
                        <el-option label="已上架" :value="1" />
                        <el-option label="已禁售" :value="2" />
                    </el-select>
                </el-form-item>
                <el-form-item prop="rst" />
                <el-form-item>
                    <el-button type="primary" @click="save" size="small">保存</el-button>
                    <el-button @click="reset" size="small">重置</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import UrlUtils from '../../../../js/UrlUtils';

export default {
    name: 'GoodsEdit',
    props: ['form'],
    data() {
        return {
            goods: {
                skuCode: '',
                deptCode: '',
                code: '',

                bigUnit: null,
                packFactor: 1,

                status: null,
                maxStock: null,
                minStock: null,
                customBar: null,
                fastBar: null,
            },
            rules: {
                bigUnit: [{ required: true, message: '请输入大单位', min: 1, max: 999999, trigger: 'blur' }],
                packFactor: [
                    {
                        required: true,
                        type: 'integer',
                        message: '请输入正整数包装因子',
                        min: 1,
                        max: 999999,
                        trigger: 'blur',
                    },
                ],
                maxStock: [
                    {
                        required: false,
                        type: 'integer',
                        message: '请输入正整数最大库存',
                        min: 1,
                        max: 999999,
                        trigger: 'blur',
                    },
                ],
                minStock: [
                    {
                        required: false,
                        type: 'integer',
                        message: '请输入正整数最低库存',
                        min: 1,
                        max: 999999,
                        trigger: 'blur',
                    },
                ],
                customBar: [
                    {
                        required: false,
                        message: '请输入正确的自定义编码',
                        trigger: 'blur',
                        pattern: window.ef.someRegs.customBar,
                    },
                ],
                fastBar: [
                    {
                        required: false,
                        message: '请输入正确的助记码',
                        trigger: 'blur',
                        pattern: window.ef.someRegs.fastBar,
                    },
                ],
            },
            hasBigUnitPrivilege: false,
        };
    },
    mounted() {
        this.goods.customBar = this.form.customBar;
        this.goods.fastBar = this.form.fastBar;
        this.goods.minStock = this.form.minStock;
        this.goods.maxStock = this.form.maxStock;
        this.goods.status = this.form.status;
        this.goods.id = this.form.id;

        this.goods.code = this.form.code;
        this.goods.skuCode = this.form.skuCode;
        this.goods.deptCode = this.form.deptCode;

        UrlUtils.HasBigUnitPrivilege(this, this.form.deptCode, (rst) => {
            this.hasBigUnitPrivilege = rst;
            if (rst) {
                this.goods.packFactor = this.form['packFactor'] || 1;
                this.goods.bigUnit = this.form['bigUnit'] || '';
            }
        });
    },
    methods: {
        save() {
            const _this = this;
            this.$refs.goods.clearValidate();
            this.$refs['goods'].validate((valid) => {
                if (!valid) {
                    return false;
                }
                if (this.form.sku.type) {
                    this.goods.packFactor = null;
                    this.goods.bigUnit = null;
                }
                UrlUtils.PatchRemote(_this, '/goods/goods/patch_c/' + _this.form.code, _this.goods, null, () => {
                    _this.$message.success('保存成功');
                    this.goBackAndReload();
                });
            });
        },
        reset() {
            this.goods.customBar = this.form.customBar;
            this.goods.fastBar = this.form.fastBar;
            this.goods.minStock = this.form.minStock;
            this.goods.maxStock = this.form.maxStock;
            this.goods.status = this.form.status;
            this.$refs['goods'].validate();
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-input-number {
    width: auto;
}

.el-input-number >>> .el-input__inner {
    text-align: left;
}
</style>
